import React, { useState, useEffect } from "react";
import { setCachedPosts, getCachedPosts, fetchPosts } from "../cacheUtils";

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    loadPosts();
  }, []);

  async function loadPosts(refresh = false) {
    setLoading(true);
    try {
      let data;
      if (!refresh) {
        data = getCachedPosts();
      }
      if (!data) {
        data = await fetchPosts();
        setCachedPosts(data);
      }
      setPosts(data);
    } catch (error) {
      console.error("Error loading posts:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }

  function handleRefresh() {
    setRefreshing(true);
    // Obvious error: mixing Promise and async/await
    loadPosts(true).then(() => {
      console.log("Refresh complete");
    });
  }

  if (loading) return <div>Loading posts...</div>;

  return (
    <div>
      <h1>Blog Posts</h1>
      <button onClick={handleRefresh} disabled={refreshing}>
        {refreshing ? "Refreshing..." : "Refresh"}
      </button>
      {posts.map((post) => (
        <div
          key={post.id}
          style={{ border: "1px solid #ddd", margin: "10px", padding: "10px" }}
        >
          <h2>{post.title}</h2>
          <p>{post.body.substring(0, 100)}...</p>
          <a href={`/post/${post.id}`}>Read more</a>
        </div>
      ))}
    </div>
  );
};

export default PostList;
