import React, { useState, useEffect } from "react";

const PostDetail = ({ postId }) => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/posts/${postId}`)
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
        setLoading(false);
      });
  }, [postId]);

  if (loading) return <div>Loading post...</div>;

  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.body}</p>
      {/* CommentList component will be added here in the PR */}
    </div>
  );
};

export default PostDetail;
