const CACHE_KEY = "blogPostsCache";
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

export function setCachedPosts(posts) {
  const cacheData = {
    posts,
    timestamp: Date.now(),
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
}

export function getCachedPosts() {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (!cachedData) return null;

  const { posts, timestamp } = JSON.parse(cachedData);
  if (Date.now() - timestamp > CACHE_EXPIRY) {
    localStorage.removeItem(CACHE_KEY);
    return null;
  }

  return posts;
}

export function fetchPosts() {
  return fetch("https://jsonplaceholder.typicode.com/posts").then(
    (response) => {
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    }
  );
}
