import React from "react";
import "./App.css";
import Navigation from "./components/Navigation";
import PostList from "./components/PostList";
import PostDetail from "./components/PostDetail";

function App() {
  // Very basic routing
  const path = window.location.pathname;
  const postId = path.startsWith("/post/") ? path.split("/")[2] : null;

  return (
    <div className="App">
      <Navigation />
      {path === "/" ? (
        <PostList />
      ) : path.startsWith("/post/") ? (
        <PostDetail postId={postId} />
      ) : (
        <div>404 Not Found</div>
      )}
    </div>
  );
}

export default App;
