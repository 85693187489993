import React from "react";

const Navigation = () => {
  return (
    <nav style={{ padding: "10px", background: "#f0f0f0" }}>
      <a href="/" style={{ marginRight: "10px" }}>
        Home
      </a>
      <a href="/about">About</a>
    </nav>
  );
};

export default Navigation;
